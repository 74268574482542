import { template as template_ddcbd818c5f44ec8b2eff6ddb4f520c5 } from "@ember/template-compiler";
const FKLabel = template_ddcbd818c5f44ec8b2eff6ddb4f520c5(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
