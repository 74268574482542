import { template as template_d72c3b9876fc4f42bc3f3bdc4f60fb15 } from "@ember/template-compiler";
const FKControlMenuContainer = template_d72c3b9876fc4f42bc3f3bdc4f60fb15(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
