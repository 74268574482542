import { template as template_d54b4146e378439fa8ede018760f1bae } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_d54b4146e378439fa8ede018760f1bae(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
